import { Badge, Box } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import TabPanel from '../components/tab-panels/tab-panel'
import UserPanel from '../components/user-panel'
import UsersTable from '../components/users-table'
import { callActivateUser, callDeactivateUser } from '../grpc/api'
import { addUser, fetchInactiveUsers, fetchUsers } from '../features/usersSlice'
import { Header, HeaderDivider } from '../styles/header'
import { Page } from '../styles/page'
import { BigTab, BigTabs } from '../styles/tabs'
import { RpcError } from 'grpc-web'
import {
    selectUsers,
    selectUsersStatus,
    selectInactiveUsers,
    selectInactiveUsersStatus,
    selectUserProfile,
    selectUsersError,
    selectInactiveUsersError,
} from '../app/selectors'
import {store, useAppDispatch, useAppSelector} from '../app/store'
import { resetProfile, setProfile } from '../features/userSlice'
import { resetSeatAssignments } from '../features/seatAssignmentsSlice'

const Users = () => {
    const dispatch = useAppDispatch()
    const users: UserInfo[] = useAppSelector(selectUsers)
    const usersStatus: LoadStatus = useAppSelector(selectUsersStatus)
    const usersError: RpcError = useAppSelector(selectUsersError)
    const inactiveUsers: UserInfo[] = useAppSelector(selectInactiveUsers)
    const inactiveUsersStatus: LoadStatus = useAppSelector(selectInactiveUsersStatus)
    const inactiveUsersError: RpcError = useAppSelector(selectInactiveUsersError)
    const selectedProfile: Profile = useAppSelector(selectUserProfile)
    const [selectedTab, setSelectedTab] = useState<number>(0)

    useEffect(() => {
        const currentUsersStatus = store.getState().users.users.status
        const currentInactiveUsersStatus = store.getState().users.inactiveUsers.status
        if (currentUsersStatus === 'not_loaded') {
            dispatch(fetchUsers())
        }
        if (usersError && usersError.message !== 'Invalid token') {
            throw usersError
        }
        if (currentInactiveUsersStatus === 'not_loaded') {
            dispatch(fetchInactiveUsers())
        }
        if (inactiveUsersError && inactiveUsersError.message !== 'Invalid token') {
            throw inactiveUsersError
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usersStatus, inactiveUsersStatus, usersError, inactiveUsersError])

    const loaded: boolean = useMemo(
        (): boolean => usersStatus === 'loaded' && inactiveUsersStatus === 'loaded',
        [usersStatus, inactiveUsersStatus]
    )

    const panelOpened: boolean = useMemo((): boolean => selectedProfile.userid !== '', [selectedProfile])

    const handleChange = useCallback(
        (_: React.SyntheticEvent, newValue: number) => {
            setSelectedTab(newValue)
        },
        [setSelectedTab]
    )

    const a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    const handleManage = useCallback(
        (id: string) => {
            const user: UserInfo = users.find(user => user.profile.userid === id)!
            dispatch(setProfile(user.profile))
        },
        [dispatch, users]
    )

    const handleActivationToggle = useCallback((activate: boolean, id: string): Promise<any> => {
        const request = activate ? callActivateUser : callDeactivateUser
        return request(id)
            .then((): void => {})
            .catch((error: RpcError): void => {
                console.error(error)
            })
    }, [])

    const handleUpdate = useCallback((): void => {
        dispatch(fetchUsers())
        dispatch(fetchInactiveUsers())
    }, [dispatch])

    const handleAddUser = useCallback(
        async (user: AddUserData) => {
            await dispatch(addUser(user))
            handleUpdate()
        },
        [dispatch, handleUpdate]
    )

    const handlePanelClose = useCallback(
        (changed: boolean): void => {
            dispatch(resetProfile())
            dispatch(resetSeatAssignments())
            if (changed) {
                handleUpdate()
            }
        },
        [dispatch, handleUpdate]
    )

    const updatePanelUser = useCallback((): void => {
        if (panelOpened && selectedProfile) {
            const user: UserInfo = users.find(user => user.profile.userid === selectedProfile.userid)!
            dispatch(setProfile(user.profile))
        }
    }, [dispatch, panelOpened, selectedProfile, users])

    useEffect(() => {
        updatePanelUser()
    }, [users, updatePanelUser])

    const tabBadge = (index: number) => {
        if (loaded) {
            const count = index === 0 ? users.length : inactiveUsers.length
            return <Badge badgeContent={count} color='warning' />
        }
        return <Badge variant='dot' color='warning' />
    }

    return (
        <Page>
            <Header>Users</Header>
            <HeaderDivider />
            <Box>
                <BigTabs value={selectedTab} onChange={handleChange} aria-label='users tabs'>
                    <BigTab label='Active users' icon={tabBadge(0)} iconPosition='end' {...a11yProps(0)} />
                    <BigTab label='Inactive users' icon={tabBadge(1)} iconPosition='end' {...a11yProps(1)} />
                </BigTabs>
            </Box>
            <TabPanel value={selectedTab} index={0}>
                {!!users?.length && (
                    <UsersTable
                        users={users}
                        active={true}
                        handleManage={handleManage}
                        handleActivationToggle={handleActivationToggle}
                        handleAddUser={handleAddUser}
                        handleUpdate={handleUpdate}
                    />
                )}
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
                {!!inactiveUsers?.length && (
                    <UsersTable
                        users={inactiveUsers}
                        active={false}
                        handleActivationToggle={handleActivationToggle}
                        handleUpdate={handleUpdate}
                    />
                )}
            </TabPanel>

            {panelOpened && selectedProfile && <UserPanel handleClose={handlePanelClose} handleUpdate={handleUpdate} />}
        </Page>
    )
}

export default Users
