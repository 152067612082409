import {
    Alert,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputAdornment,
    MenuItem,
    SelectChangeEvent,
} from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import StandardButton from '../styles/button'
import StandardDialog, { DialogContainer, FormDialog, FormDialogHalf, FormDialogRow } from '../styles/dialog'
import { PlainTextField } from '../styles/textfield'
import {
    selectAccountProfileDomains,
    selectAccountProfileStatus,
    selectAccountProfileNewUserStatus,
    selectAccountProfileNewUserError,
} from '../app/selectors'
import {store, useAppDispatch, useAppSelector} from '../app/store'
import { PlainSelect } from '../styles/select'
import { fetchAccountProfile, resetNewUser } from '../features/usersSlice'

const AddUserDialog = ({ open, handleOpen, handleAddUser }: AddUserModalDialogProps) => {
    const dispatch = useAppDispatch()
    const domains: string[] = useAppSelector(selectAccountProfileDomains)
    const status: string = useAppSelector(selectAccountProfileStatus)
    const newUserStatus: string = useAppSelector(selectAccountProfileNewUserStatus)
    const newUserError = useAppSelector(selectAccountProfileNewUserError)
    const [showAlert, setShowAlert] = useState<boolean>(false)

    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [emailUsername, setEmailUsername] = useState<string>('')
    const [domain, setDomain] = useState<string>('')

    useEffect(() => {
        const currentStatus = store.getState().users.accountProfile.status
        if (currentStatus === 'not_loaded') {
            dispatch(fetchAccountProfile())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status])

    useEffect(() => {
        if (!domain || domain === '') {
            setDomain(domains[0])
        }
    }, [domain, domains])

    const handleClose = useCallback(() => {
        if (handleOpen) {
            handleOpen(false)
        }
        dispatch(resetNewUser())
    }, [dispatch, handleOpen])

    useEffect(() => {
        if (!showAlert && newUserStatus === 'failed') {
            setShowAlert(true)
        } else if (newUserStatus === 'success') {
            handleClose()
        }
    }, [newUserStatus, newUserError, showAlert, handleClose])

    const handleConfirmation = useCallback(() => {
        setShowAlert(false)
        const addUserData: AddUserData = {
            email: `${emailUsername}@${domain}`,
            salutation: '',
            firstName: firstName,
            lastName: lastName,
        }
        if (handleAddUser) {
            handleAddUser(addUserData)
        }
    }, [firstName, lastName, emailUsername, domain, handleAddUser])

    const handleInputChange = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (event.target.id === 'first-name') {
            setFirstName(event.target.value)
        } else if (event.target.id === 'last-name') {
            setLastName(event.target.value)
        } else if (event.target.id === 'email-username') {
            setEmailUsername(event.target.value)
        }
    }, [])

    const handleDomainChange = (event: SelectChangeEvent<unknown>) => {
        setDomain(String(event.target.value))
    }

    const alertText = useMemo((): string => {
        if (newUserError) {
            return newUserError.message
        }
        return 'User added successfully'
    }, [newUserError])

    const isAddDisabled = useMemo(() => {
        return !firstName.length || !lastName.length || !emailUsername.length || !domain.length
    }, [firstName, lastName, emailUsername, domain])

    return (
        <StandardDialog
            open={open}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogContainer>
                <DialogTitle id='alert-dialog-title'>Add User / Admin</DialogTitle>
                <DialogContent>
                    {domains.length > 0 && (
                        <FormDialog>
                            <FormDialogRow>
                                <FormDialogHalf>
                                    <PlainTextField
                                        id='first-name'
                                        type='text'
                                        label='First name'
                                        fullWidth
                                        value={firstName || ''}
                                        onChange={handleInputChange}
                                        variant='standard'
                                    />
                                </FormDialogHalf>
                                <FormDialogHalf>
                                    <PlainTextField
                                        id='last-name'
                                        type='text'
                                        label='Last name'
                                        fullWidth
                                        value={lastName || ''}
                                        onChange={handleInputChange}
                                        variant='standard'
                                    />
                                </FormDialogHalf>
                            </FormDialogRow>
                            <FormDialogRow>
                                <FormDialogHalf>
                                    <PlainTextField
                                        id='email-username'
                                        type='text'
                                        label='Email address'
                                        fullWidth
                                        value={emailUsername || ''}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    {domains.length === 1 && <span>@{domain}</span>}
                                                    {domains.length > 1 && (
                                                        <FormControl variant='standard'>
                                                            <PlainSelect
                                                                id='domain'
                                                                labelId='domain'
                                                                label='Domain'
                                                                value={domain || ''}
                                                                onChange={handleDomainChange}>
                                                                {domains.map((e: string) => (
                                                                    <MenuItem key={e} value={e}>
                                                                        @{e}
                                                                    </MenuItem>
                                                                ))}
                                                            </PlainSelect>
                                                        </FormControl>
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant='standard'
                                    />
                                </FormDialogHalf>
                            </FormDialogRow>
                            {showAlert && <Alert severity={'error'}>{alertText}</Alert>}
                        </FormDialog>
                    )}
                    {domains.length === 0 && <span>No available domains to add users</span>}
                </DialogContent>

                <DialogActions>
                    <StandardButton variant='outlined' onClick={handleClose}>
                        Cancel
                    </StandardButton>
                    <StandardButton onClick={handleConfirmation} disabled={isAddDisabled}>
                        Add
                    </StandardButton>
                </DialogActions>
            </DialogContainer>
        </StandardDialog>
    )
}

export default AddUserDialog
