import { AlertColor, CircularProgress, FormControl, MenuItem, SelectChangeEvent } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {store, useAppDispatch, useAppSelector } from '../../app/store'
import { setProfileModified, updateProfile, changeUserType, fetchUser } from '../../features/userSlice'
import StandardButton from '../../styles/button'
import StandardInputLabel from '../../styles/label'
import { PlainSelect } from '../../styles/select'
import { Spacing } from '../../styles/spacing'
import { PlainTextField } from '../../styles/textfield'
import { Buttons, ManagePanelAccount, ManagePanelContainer } from '../../styles/user-panel'
import {
    selectUserError,
    selectUserProfile,
    selectUserProfileModified,
    selectUserStatus,
    selectUserTypeStatus,
} from '../../app/selectors'
import { StandardAlert } from '../../styles/alert'
import StandardSnackbar from '../../styles/snackbar'
import { RpcError } from 'grpc-web'
import countries from '../../assets/countries.json'

const ManageAccountPanel = ({ handleChanged, handleClosing }: ManagePanelProps) => {
    const dispatch = useAppDispatch()
    const profile: Profile = useAppSelector(selectUserProfile)
    const profileModified: Profile = useAppSelector(selectUserProfileModified)
    const status: LoadStatus = useAppSelector(selectUserStatus)
    const error: RpcError = useAppSelector(selectUserError)
    const usertypeStatus: LoadStatus = useAppSelector(selectUserTypeStatus)
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false)

    useEffect(() => {
        const currentStatus = store.getState().user.status
        if (currentStatus === 'not_loaded') {
            dispatch(fetchUser(profile.userid))
        } else if (currentStatus === 'success' || currentStatus === 'failed') {
            setShowSnackbar(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile, status])

    const profileLoaded: boolean = useMemo((): boolean => status === 'loaded' || status === 'failed', [status])

    const showProvince: boolean = process.env.REACT_APP_SHOW_PROVINCE === 'true'

    const handleTextfield = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (event.target.id === 'salutation') {
                dispatch(setProfileModified({ ...profileModified, salutation: event.target.value }))
            } else if (event.target.id === 'firstname') {
                dispatch(setProfileModified({ ...profileModified, firstname: event.target.value }))
            } else if (event.target.id === 'lastname') {
                dispatch(setProfileModified({ ...profileModified, lastname: event.target.value }))
            } else if (event.target.id === 'email') {
                dispatch(setProfileModified({ ...profileModified, email: event.target.value }))
            } else if (event.target.id === 'address.street') {
                dispatch(
                    setProfileModified({
                        ...profileModified,
                        address: { ...profileModified.address, street: event.target.value },
                    })
                )
            } else if (event.target.id === 'address.postalcode') {
                dispatch(
                    setProfileModified({
                        ...profileModified,
                        address: { ...profileModified.address, postalcode: event.target.value },
                    })
                )
            } else if (event.target.id === 'address.city') {
                dispatch(
                    setProfileModified({
                        ...profileModified,
                        address: { ...profileModified.address, city: event.target.value },
                    })
                )
            } else if (event.target.id === 'address.province') {
                dispatch(
                    setProfileModified({
                        ...profileModified,
                        address: { ...profileModified.address, province: event.target.value },
                    })
                )
            } else if (event.target.id === 'address.country') {
                dispatch(
                    setProfileModified({
                        ...profileModified,
                        address: { ...profileModified.address, country: event.target.value },
                    })
                )
            }
        },
        [dispatch, profileModified]
    )

    const isUserChanged: boolean = useMemo(
        () => JSON.stringify(profile) !== JSON.stringify(profileModified),
        [profile, profileModified]
    )

    const isUpdatingUser: boolean = useMemo(
        () => status === 'loading' || usertypeStatus === 'loading',
        [status, usertypeStatus]
    )

    const handleCountryChange = (event: SelectChangeEvent<unknown>) => {
        dispatch(
            setProfileModified({
                ...profileModified,
                address: { ...profileModified.address, countrycode: event.target.value as string },
            })
        )
    }

    const handleUserTypeChange = (event: SelectChangeEvent<unknown>) => {
        dispatch(
            setProfileModified({
                ...profileModified,
                usertype: Number(event.target.value),
            })
        )
    }

    const handleSave = useCallback(() => {
        handleChanged(true)
        if (isUserChanged) {
            dispatch(updateProfile(profileModified))
        }

        if (profile.usertype !== profileModified.usertype) {
            dispatch(changeUserType({ userId: profileModified.userid, userType: profileModified.usertype }))
        }
    }, [dispatch, profile, profileModified, isUserChanged, handleChanged])

    const alertText = useMemo((): string => {
        if (error) {
            return error.message
        }
        return 'Subscriptions updated successfully'
    }, [error])

    const alertSeverity: AlertColor = useMemo((): AlertColor => {
        return error ? 'error' : 'success'
    }, [error])

    const handleCloseSnackbar = useCallback(() => {
        setShowSnackbar(false)
    }, [setShowSnackbar])

    return (
        <ManagePanelContainer>
            {!profileLoaded && <CircularProgress color='inherit' />}
            {profileLoaded && (
                <form onSubmit={handleSave}>
                    <ManagePanelAccount>
                        <PlainTextField
                            id='salutation'
                            label='Salutation'
                            value={profileModified.salutation}
                            variant='standard'
                            disabled={isUpdatingUser}
                            onChange={handleTextfield}
                        />
                        <PlainTextField
                            id='firstname'
                            label='First name'
                            value={profileModified.firstname}
                            variant='standard'
                            disabled={isUpdatingUser}
                            onChange={handleTextfield}
                        />
                        <PlainTextField
                            id='lastname'
                            label='Last name'
                            value={profileModified.lastname}
                            variant='standard'
                            disabled={isUpdatingUser}
                            onChange={handleTextfield}
                        />
                        <PlainTextField
                            id='address.street'
                            label='Street'
                            value={profileModified.address.street}
                            variant='standard'
                            disabled={isUpdatingUser}
                            onChange={handleTextfield}
                        />
                        <PlainTextField
                            id='address.postalcode'
                            label='Postal code'
                            value={profileModified.address.postalcode}
                            variant='standard'
                            disabled={isUpdatingUser}
                            onChange={handleTextfield}
                        />
                        <PlainTextField
                            id='address.city'
                            label='City'
                            value={profileModified.address.city}
                            variant='standard'
                            disabled={isUpdatingUser}
                            onChange={handleTextfield}
                        />
                        {showProvince && (
                            <PlainTextField
                                id='address.province'
                                label='Province'
                                value={profileModified.address.province}
                                variant='standard'
                                disabled={isUpdatingUser}
                                onChange={handleTextfield}
                            />
                        )}
                        <FormControl variant='standard'>
                            <StandardInputLabel id='role-label'>Country</StandardInputLabel>
                            <PlainSelect
                                id='address.country'
                                labelId='country-label'
                                label='Country'
                                value={profileModified.address.countrycode}
                                disabled={isUpdatingUser}
                                displayEmpty
                                onChange={handleCountryChange}>
                                <MenuItem value=''>
                                    <em>Select country</em>
                                </MenuItem>
                                {countries.map(country => (
                                    <MenuItem key={`country-${country.code}`} value={country.code}>
                                        {country.name}
                                    </MenuItem>
                                ))}
                            </PlainSelect>
                        </FormControl>
                        <FormControl variant='standard'>
                            <StandardInputLabel id='role-label'>Role</StandardInputLabel>
                            <PlainSelect
                                id='role'
                                labelId='role-label'
                                label='Role'
                                value={profileModified.usertype}
                                disabled={isUpdatingUser}
                                onChange={handleUserTypeChange}>
                                <MenuItem value={0}>User</MenuItem>
                                <MenuItem value={1}>Admin</MenuItem>
                                <MenuItem value={2}>Billing</MenuItem>
                            </PlainSelect>
                        </FormControl>
                    </ManagePanelAccount>
                    <Spacing />
                    <Buttons>
                        <StandardButton variant='outlined' onClick={handleClosing}>
                            Cancel
                        </StandardButton>
                        <StandardButton type='submit' disabled={!isUserChanged || isUpdatingUser}>
                            Save
                        </StandardButton>
                    </Buttons>
                </form>
            )}
            <StandardSnackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <StandardAlert onClose={handleCloseSnackbar} severity={alertSeverity}>
                    {alertText}
                </StandardAlert>
            </StandardSnackbar>
        </ManagePanelContainer>
    )
}

export default ManageAccountPanel
