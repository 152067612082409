import { CheckCircle, SearchRounded } from '@mui/icons-material'
import { CircularProgress, InputAdornment, TableSortLabel } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { fetchSubscriptions } from '../features/subscriptionsSlice'
import {store, useAppDispatch, useAppSelector} from '../app/store'
import { Header, HeaderDivider } from '../styles/header'
import { Page } from '../styles/page'
import {
    StandardTable,
    StandardTableBody,
    StandardTableCell,
    StandardTableContainer,
    StandardTableHead,
    StandardTableRow,
    StandardTableToolbar,
} from '../styles/table'
import StandardTextField from '../styles/textfield'
import { selectSubscriptions, selectSubscriptionsError, selectSubscriptionsStatus } from '../app/selectors'
import { formatLocaleDate } from '../utils/date'
import { RpcError } from 'grpc-web'

const Suscriptions = () => {
    const dispatch = useAppDispatch()
    const subscriptions: Subscription[] = useAppSelector(selectSubscriptions)
    const status: LoadStatus = useAppSelector(selectSubscriptionsStatus)
    const error: RpcError = useAppSelector(selectSubscriptionsError)
    const [searchBy, setSearchBy] = useState<string>('')
    const [searchByDelayed, setSearchByDelayed] = useState<string>('')

    useEffect((): void => {
        const currentStatus = store.getState().subscriptions.status
        if (currentStatus === 'not_loaded') {
            dispatch(fetchSubscriptions())
        }
        if (error && error.message !== 'Invalid token') {
            throw error
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, error])

    useEffect(() => {
        const timer = setTimeout(() => setSearchBy(searchByDelayed), 500)
        return (): void => clearTimeout(timer)
    }, [searchByDelayed])

    const loaded: boolean = useMemo((): boolean => status === 'loaded', [status])

    const hasOptionalProducts: boolean = useMemo((): boolean => {
        const lines: SubscriptionLine[] = []
        subscriptions.forEach((sub: Subscription): void => {
            sub.linesList.forEach((line: SubscriptionLine): number => lines.push(line))
        })
        return (
            lines
                .map((line: SubscriptionLine): number => {
                    return line.optionalproductsList.length
                })
                .filter((item: number): number => item).length > 0
        )
    }, [subscriptions])

    const columns: ColumnData[] = useMemo((): ColumnData[] => {
        const cols: ColumnData[] = [
            { id: 'valid', name: 'Status' },
            { id: 'name', name: 'Contract' },
            { id: 'edition', name: 'Edition' },
            { id: 'optional-products', name: 'Optional products' },
            { id: 'seats', name: 'Available/Total Seats' },
            { id: 'expiration', name: 'Expiration' },
        ]
        if (!hasOptionalProducts) {
            cols.splice(3, 1)
        }
        return cols
    }, [hasOptionalProducts])

    const [sorting, setSorting] = useState<TableSorting>({
        column: columns[0].id,
        direction: 'desc',
    })

    const sortRows = useCallback(
        (a: RowData, b: RowData) => {
            for (let i = 0; i < columns.length; i++) {
                if (sorting.column === columns[i].id) {
                    if (a.cells[i] === b.cells[i]) {
                        return 0
                    } else {
                        const direction = sorting.direction === 'asc' ? 1 : -1
                        return a.cells[i] < b.cells[i] ? direction : -direction
                    }
                }
            }
            return -1
        },
        [sorting, columns]
    )

    const rows: RowData[] = useMemo((): RowData[] => {
        const rows_: RowData[] = []
        for (const sub of subscriptions) {
            for (const line of sub.linesList) {
                const cells: string[] = [
                    sub.expired ? 'expired' : 'valid',
                    sub.name,
                    line.product.name,
                    line.optionalproductsList.map(product => product.name).join(', '),
                    `${line.seats - line.usedseats}/${line.seats}`,
                    formatLocaleDate(sub.todate),
                ]
                if (!hasOptionalProducts) {
                    cells.splice(3, 1)
                }
                rows_.push({
                    id: line.id,
                    cells,
                })
            }
        }
        return [...rows_]
            .sort((a, b) => sortRows(a, b))
            .filter((row: RowData): boolean => {
                if (searchBy) {
                    const matches: boolean[] = row.cells
                        .map(
                            (cell: string | number): boolean =>
                                String(cell).toLowerCase().indexOf(searchBy.toLowerCase()) === -1
                        )
                        .filter((result: boolean): boolean => !result)
                    return !!matches.length
                }
                return true
            })
    }, [subscriptions, searchBy, hasOptionalProducts, sortRows])

    const handleSearch = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setSearchByDelayed(event.target.value)
        },
        [setSearchByDelayed]
    )

    const isColumnActive = useCallback(
        (id: string): boolean | undefined => {
            return sorting.column === id
        },
        [sorting]
    )

    const handleChangeSorting = useCallback(
        (event: React.PointerEvent<HTMLSpanElement>) => {
            const id: string = (event.target as HTMLSpanElement).id
            if (isColumnActive(id)) {
                if (sorting.direction === 'asc') {
                    setSorting({ ...sorting, direction: 'desc' })
                } else {
                    setSorting({ ...sorting, direction: 'asc' })
                }
            } else {
                setSorting({ column: id, direction: 'desc' })
            }
        },
        [sorting, isColumnActive]
    )

    const getColumnDirection = useCallback(
        (id: string): TableSortingDirection => {
            if (isColumnActive(id)) {
                return sorting.direction
            }
            return 'desc'
        },
        [sorting, isColumnActive]
    )

    return (
        <Page>
            <Header>Subscriptions</Header>
            <HeaderDivider />
            {!loaded && <CircularProgress color='inherit' />}
            {loaded && (
                <StandardTableContainer>
                    <StandardTableToolbar>
                        <StandardTextField
                            id='users-search'
                            placeholder='Search...'
                            type='search'
                            variant='standard'
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <SearchRounded />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </StandardTableToolbar>
                    {!!rows.length && (
                        <StandardTable>
                            <StandardTableHead>
                                <StandardTableRow>
                                    {columns.map((column, i) => {
                                        if (columns[i].id === 'valid') {
                                            return <StandardTableCell className='compact' />
                                        } else {
                                            return (
                                                <StandardTableCell
                                                    key={column.id}
                                                    align={columns[i].id === 'seats' ? 'center' : 'left'}>
                                                    <TableSortLabel
                                                        active={isColumnActive(column.id)}
                                                        direction={getColumnDirection(column.id)}
                                                        id={column.id}
                                                        onClick={handleChangeSorting}>
                                                        {column.name}
                                                    </TableSortLabel>
                                                </StandardTableCell>
                                            )
                                        }
                                    })}
                                </StandardTableRow>
                            </StandardTableHead>
                            <StandardTableBody>
                                {rows?.map(row => (
                                    <StandardTableRow key={row.id}>
                                        {row.cells.map((cell, i) => {
                                            if (columns[i].id === 'valid') {
                                                return (
                                                    <StandardTableCell className='compact'>
                                                        {cell === 'valid' && <CheckCircle color='success' />}
                                                    </StandardTableCell>
                                                )
                                            } else {
                                                return (
                                                    <StandardTableCell
                                                        key={`${row.id}-${columns[i].id}`}
                                                        align={columns[i].id === 'seats' ? 'center' : 'left'}>
                                                        {cell}
                                                    </StandardTableCell>
                                                )
                                            }
                                        })}
                                    </StandardTableRow>
                                ))}
                            </StandardTableBody>
                        </StandardTable>
                    )}
                </StandardTableContainer>
            )}
        </Page>
    )
}

export default Suscriptions
